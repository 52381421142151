import React from 'react';

const Experience = () => {
  return (
    <div>
      <h2>// Experience</h2>
      <p>Previous Experience.</p>
    </div>
  );
};

export default Experience;
