import React, { useState } from 'react';
import './App.css';
import Home from './components/Home';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Experience from './components/Experience'; // Import the new Experience component
import { Linkedin } from '@geist-ui/icons';
import { Github } from '@geist-ui/icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  const [selectedPage, setSelectedPage] = useState('Home');

  const renderPage = () => {
    switch (selectedPage) {
      case 'Home':
        return <Home />;
      case 'Projects':
        return <Projects />;
      case 'Contact':
        return <Contact />;
      case 'Experience': // Add Experience to the switch case
        return <Experience />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="App">
      <div className="sidebar">
        <h1>Tom Comrie</h1>
        <div className="bio">
          <p>Student Software Engineer and York Formula Student Team Principal</p>
        </div>
        <nav>
          <ul>
            <li onClick={() => setSelectedPage('Home')}>Home</li>
            <li onClick={() => setSelectedPage('Projects')}>Projects</li>
            <li onClick={() => setSelectedPage('Experience')}>Experience</li> {/* Add Experience to the nav */}
            <li onClick={() => setSelectedPage('Contact')}>Contact</li>
          </ul>
        </nav>
        <div className="social-links">
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <Linkedin className="social-icon" />
          </a>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <Github className="social-icon" />
          </a>
        </div>
      </div>
      <div className="content">
        <TransitionGroup component={null}>
          <CSSTransition
            key={selectedPage}
            classNames="page"
            timeout={500}
          >
            <div className="page">
              {renderPage()}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default App;
