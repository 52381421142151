import React from 'react';

const Contact = () => {
  return (
    <div>
      <h2>// Contact</h2>
      <p>Get in touch with me.</p>
    </div>
  );
};

export default Contact;
